import { template as template_3db3281498a6402c91b344a646635987 } from "@ember/template-compiler";
const FKText = template_3db3281498a6402c91b344a646635987(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
