import { template as template_e452e28fa0794c2ea3f3b93c02cc5ef3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_e452e28fa0794c2ea3f3b93c02cc5ef3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
