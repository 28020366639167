import { template as template_b3d4b17f21b8458c99a33824a7f8eb20 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_b3d4b17f21b8458c99a33824a7f8eb20(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
