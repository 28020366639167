import { template as template_a34bcc336e3a41f98b08e593ece17555 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a34bcc336e3a41f98b08e593ece17555(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
