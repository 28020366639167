import { template as template_b7148e0825004f0c8ffa2e9e0c984566 } from "@ember/template-compiler";
const FKLabel = template_b7148e0825004f0c8ffa2e9e0c984566(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
