import { template as template_ba20c5d9f61a42dd8a4c2aaa792cf17e } from "@ember/template-compiler";
const EmptyState = template_ba20c5d9f61a42dd8a4c2aaa792cf17e(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
